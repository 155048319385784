import React from "react";
import { Input, Select } from "antd";

export default function TableHeader({
  type = "",
  subTable = false,
  paramObj = {},
  getList = () => {},
  searchFor = null,
  filterFor = null,
  filterOptions = [],
  otherFilters = null,
}) {
  return (
    <div
      style={
        subTable
          ? {
              width: "95%",
              marginLeft: "auto",
              borderTop: "1px solid #333",
              marginTop: type === "Resource" ? 30 : 10,
              paddingTop: 8,
            }
          : {}
      }
      className="table-header"
    >
      <h3 style={{ marginBottom: searchFor ? 0 : subTable ? 8 : 16 }}>
        {type} Management
      </h3>
      {otherFilters && <div className="filters-container">{otherFilters}</div>}
      {searchFor && (
        <Input.Search
          enterButton
          placeholder={"Enter " + searchFor}
          onSearch={(v) => {
            if ((!paramObj.searchKeyword && v) || paramObj.searchKeyword !== v)
              getList({ ...paramObj, searchKeyword: v, page: 1 });
          }}
          style={{ width: "20%", marginBottom: 12, minWidth: 260 }}
          allowClear
        />
      )}
      {filterFor && (
        <Select
          placeholder={"Select " + filterFor}
          style={{ width: "20%", marginBottom: 12, minWidth: 260 }}
          onChange={(v) => getList({ ...paramObj, rarityClass: v, page: 1 })}
        >
          {filterOptions.map((opt) => (
            <Select.Option key={opt.value} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
}
