import React from "react";
import { Modal } from "antd";

export default function ConfirmPopup({
  type = "course",
  selected = {},
  setSelected = () => {},
  dataHandler = () => {},
}) {
  return (
    <Modal
      okText="Yes, Delete"
      onOk={() => dataHandler("delete")}
      onCancel={() => setSelected({})}
      title={<h3 style={{ textTransform: "capitalize" }}>Delete {type}</h3>}
      confirmLoading={selected.loading}
      open
      centered
      maskClosable={false}
      cancelButtonProps={{ type: "danger" }}
      maskStyle={{ opacity: 1 }}
    >
      <h3 style={{ color: "#333", margin: "auto" }}>
        Are you sure to <b>delete</b> the selected {type} ?
      </h3>
    </Modal>
  );
}
