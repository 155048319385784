import React from "react";
import { Col, Drawer, Form, Row, Skeleton, Upload } from "antd";

import ImageView from "./ImageView";

export default function DrawerView({
  loading = false,
  title = "",
  data = [{ label: "Title", value: "title new" }],
  onClose = () => {},
  children = null,
  ...props
}) {
  return (
    <Drawer
      title={title + " Details"}
      onClose={onClose}
      width={window.innerWidth < 900 ? "85%" : "40%"}
      placement="right"
      open
      {...props}
    >
      {children}
      {data?.length > 0 && (
        <Form layout="horizontal" labelAlign="left" labelWrap>
          <Row gutter={[16, 8]}>
            {data.map((d = {}, i = 0) => (
              <Col key={i} xs={24} xxl={d.fullWidth || d.multiline ? 24 : 12}>
                <Form.Item
                  label={
                    <div style={{ color: "#555", fontSize: 13 }}>{d.label}</div>
                  }
                  labelCol={{
                    xs: d.multiline ? 24 : 8,
                    xxl: d.fullWidth && 4,
                    style: { paddingBottom: 0 },
                  }}
                  style={{ marginBottom: 0 }}
                >
                  {d.file || d.image ? (
                    <Skeleton
                      active
                      title={{
                        style: { height: 110, width: 110, marginTop: 10 },
                      }}
                      paragraph={false}
                      loading={loading}
                    >
                      <div
                        style={{
                          padding: 10,
                          marginBottom: 10,
                          border: "1px solid #d9d9d9",
                          width: "max-content",
                        }}
                      >
                        {d.image ? (
                          <ImageView
                            src={d.value || ""}
                            style={{ height: 100, width: 100 }}
                          />
                        ) : (
                          <Upload
                            fileList={[
                              {
                                url: d.value || "",
                                uid: "-1",
                                status: "done",
                                name: "",
                              },
                            ]}
                            listType="picture-card"
                            disabled
                          />
                        )}
                      </div>
                    </Skeleton>
                  ) : d.custom ? (
                    d.custom
                  ) : (
                    <span
                      style={{
                        fontSize: 15,
                        textTransform: d.multiline ? "unset" : "capitalize",
                        fontWeight: d.multiline ? "400" : "500",
                        color: "#000",
                        overflowWrap: "anywhere",
                      }}
                    >
                      <Skeleton
                        title={
                          d.multiline ? false : { style: { marginTop: 10 } }
                        }
                        active
                        paragraph={
                          d.multiline
                            ? { style: { marginTop: 12 }, rows: 4 }
                            : false
                        }
                        loading={loading}
                        round
                      >
                        {d.value}
                      </Skeleton>
                    </span>
                  )}
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      )}
    </Drawer>
  );
}
