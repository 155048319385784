import React from "react";
import { Button, Skeleton, Table } from "antd";
import { AiFillDelete, AiFillEye, AiFillPlusCircle } from "react-icons/ai";

import TableHeader from "./TableHeader";

export default function TableContainer({
  type = "Course",
  columns = [],
  dataSource = [],
  loading = false,
  paramObj = {},
  setSelected = () => {},
  getList = () => {},
  subTable = false,
  hideDelete = false,
  searchFor = null,
  ...props
}) {
  const updatedColumns = [
    {
      title: "S. no.",
      key: "sno",
      width: 80,
      render: (t, r, i) => (paramObj.page - 1) * paramObj.limit + i + 1,
    },
    ...columns,
    {
      title: (
        <Button
          style={{ borderRadius: 5 }}
          onClick={() => setSelected({ new: true })}
          type="primary"
          size="middle"
        >
          <AiFillPlusCircle style={{ marginBottom: -2, marginRight: 5 }} />
          Add {type}
        </Button>
      ),
      key: "active",
      dataIndex: "active",
      align: "center",
      render: (_, item) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            shape="circle"
            title={"View " + type}
            onClick={() => setSelected({ ...item, view: true })}
            size="middle"
          >
            <AiFillEye style={{ marginBottom: -1.5 }} />
          </Button>
          {!hideDelete && (
            <Button
              type="danger"
              shape="circle"
              style={{ marginLeft: 10 }}
              title={"Delete " + type}
              onClick={() => setSelected({ deleteId: item._id })}
              size="middle"
            >
              <AiFillDelete style={{ marginBottom: -1.5 }} />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <TableHeader
        type={type}
        paramObj={paramObj}
        getList={getList}
        searchFor={searchFor}
        subTable={subTable}
      />
      <Table
        bordered
        scroll={{ x: window.innerWidth < 800 ? true : false }}
        size="small"
        {...props}
        style={subTable ? { width: "95%", marginLeft: "auto" } : {}}
        rowKey={(item) => item._id}
        // locale={{
        //   emptyText: (
        //     <Empty
        //       image={Empty.PRESENTED_IMAGE_SIMPLE}
        //       description={
        //         <b>
        //           Currently there are no{" "}
        //           {type?.toLowerCase()?.replace("all ", "")}s
        //         </b>
        //       }
        //     />
        //   ),
        // }}
        pagination={{
          total: paramObj.total || 1,
          current: paramObj.page,
          pageSize: paramObj.limit,
          hideOnSinglePage: true,
          onChange: (page, limit) => getList({ ...paramObj, page, limit }),
          showTotal: (total) => `Total ${total} ${type}s`,
          style: {
            marginRight: 15,
            color: "#fff",
            display: "flex",
            justifyContent: "flex-end",
          },
        }}
        columns={
          loading
            ? updatedColumns.map((column) => ({
                ...column,
                render: () => (
                  <Skeleton key={column.title} round active paragraph={false} />
                ),
              }))
            : updatedColumns
        }
        dataSource={
          loading
            ? [...Array(paramObj.limit || 10)].map((_, index) => ({
                _id: `key${index}`,
              }))
            : dataSource
        }
      />
    </>
  );
}
