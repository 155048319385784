import React, { useState } from "react";
import { Button, Card, message } from "antd";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import {
  API_CALL,
  COURSE_SERVER,
  defaultParamObj,
  FORM_DATA_API_CALL,
  useGetListApi,
} from "../utils/network";
import TableContainer from "../components/TableContainer";
import ChapterTableView from "./Chapter";
import ResourceTableView from "./Resource";
import FormPopup from "../components/FormPopup";
import ConfirmPopup from "../components/ConfirmPopup";
import UploadView from "../components/UploadView";
import ImageView from "../components/ImageView";

const CATEGORY_TYPES = {
  starstake: "StarStake",
  stakehub: "StakeHub",
  creatorhub: "CreatorHub",
  snft: "Brand Club",
  bucks: "NFT Bucks",
  accessNft: "Access NFT",
};

const accessTypeOptions = [
  { value: "0", label: "No, for Star & Fan both" },
  { value: "1", label: "Yes, for Star only" },
];

const columns = [
  {
    title: "Image",
    dataIndex: "thumbnailPath",
    key: "thumbnailPath",
    width: 80,
    align: "center",
    render: (thumbnailPath) => <ImageView src={thumbnailPath} />,
  },
  { title: "Course Title", dataIndex: "title", key: "title" },
  {
    title: "Course Category",
    dataIndex: "category",
    key: "category",
    render: (category = "") => CATEGORY_TYPES[category] || category,
  },
  {
    title: "Course Restriction",
    dataIndex: "restricted",
    key: "restricted",
    render: (r) => (r ? "Restricted Access" : "Public Access"),
  },
  {
    title: "For Star Only",
    dataIndex: "forStarOnly",
    key: "forStarOnly",
    render: (forStarOnly = 0) => (forStarOnly ? "Yes" : "No"),
  },
];

const categoryOptions = Object.entries(CATEGORY_TYPES).map(
  ([value, label]) => ({ value, label })
);

const restrictedOptions = [
  { value: "0", label: "Public Access" },
  { value: "1", label: "Restricted Access" },
];

export default function Course() {
  const [selected, setSelected] = useState({});
  const { getList, list, loading, paramObj } = useGetListApi({
    url: COURSE_SERVER + "getAll",
    params: { ...defaultParamObj, limit: 4 },
  });

  const dataHandler = (type = "") => {
    if (type === "delete") {
      setSelected({ ...selected, loading: true });
      API_CALL.delete(COURSE_SERVER + `${selected.deleteId}/0`)
        .then((res) => {
          if (res.status === 200) {
            if (list.length > 1 || paramObj.page === 1) getList();
            else getList({ ...paramObj, page: paramObj.page - 1 });
            message.success("Course deleted successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    } else if (type === "create" || type === "update") {
      if (
        (type === "create" && !selected.file) ||
        !selected.title ||
        !selected.category ||
        !selected.restricted ||
        !selected.description
      ) {
        setSelected({ ...selected, showError: true });
      } else {
        setSelected({ ...selected, loading: true });
        const bodyFormData = new FormData();
        if (selected.file) bodyFormData.append("file", selected.file);
        bodyFormData.append("title", selected.title);
        bodyFormData.append("forStarOnly", selected.forStarOnly);
        bodyFormData.append("category", selected.category);
        bodyFormData.append("restricted", selected.restricted);
        bodyFormData.append("description", selected.description);
        const apiEndUrl = type === "create" ? type : `update/${selected._id}`;
        FORM_DATA_API_CALL({
          method: type === "create" ? "post" : "put",
          url: COURSE_SERVER + apiEndUrl,
          data: bodyFormData,
        })
          .then((res) => {
            if (res.status === 200) {
              getList();
              message.success("Course " + type + "d successfully");
            }
            setSelected({});
          })
          .catch((err) => {
            console.log(err);
            setSelected({});
          });
      }
    }
  };

  return (
    <>
      {selected.deleteId && (
        <ConfirmPopup
          type="course"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
        />
      )}
      {(selected.new || selected._id) && (
        <FormPopup
          type="Course"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
          formItems={[
            {
              label: "Course Thumbnail",
              value: "file",
              errorText: selected.thumbnailPath
                ? " "
                : "Please upload a thumbnail image",
              custom: (
                <UploadView
                  urlKey="thumbnailPath"
                  selected={selected}
                  setSelected={setSelected}
                />
              ),
            },
            {
              label: "Course Title",
              value: "title",
              errorText: "Please enter the course title",
              placeholder: "Enter course title",
            },
            {
              label: "Course for Star only",
              value: "forStarOnly",
              viewValue: "isForStarOnly",
              errorText: "Please select course access for star",
              placeholder: "Select course access for star",
              options: accessTypeOptions,
            },
            {
              label: "Course Category",
              value: "category",
              errorText: "Please select course category",
              placeholder: "Select course category",
              options: categoryOptions,
            },
            {
              label: "Course Restriction",
              value: "restricted",
              viewValue: "restriction",
              errorText: "Please select course restriction",
              placeholder: "Select course restriction",
              options: restrictedOptions,
            },
            {
              label: "Course Description",
              value: "description",
              errorText: "Please enter the course description",
              placeholder: "Enter course description",
              multiline: true,
            },
          ]}
        />
      )}
      <Card bordered className="content-card">
        <TableContainer
          type="Course"
          getList={getList}
          loading={loading}
          paramObj={paramObj}
          setSelected={(data = {}) =>
            data.view
              ? setSelected({
                  ...data,
                  forStarOnly: data.forStarOnly.toString(),
                  isForStarOnly:
                    accessTypeOptions[data.forStarOnly ? 1 : 0].label,
                  restricted: data.restricted.toString(),
                  restriction: restrictedOptions[data.restricted ? 1 : 0].label,
                })
              : setSelected(data)
          }
          columns={columns}
          dataSource={list}
          expandedRowRender={(course) => (
            <>
              <ChapterTableView course={course} />
              <ResourceTableView course={course} />
            </>
          )}
          expandIcon={(props) =>
            !props.expanded ? (
              <Button
                size="small"
                onClick={(e) => props.onExpand(props.record, e)}
              >
                <AiOutlinePlus />
              </Button>
            ) : (
              <Button
                size="small"
                onClick={(e) => props.onExpand(props.record, e)}
              >
                <AiOutlineMinus />
              </Button>
            )
          }
        />
      </Card>
    </>
  );
}
