import React, { useState } from "react";
import { message } from "antd";

import TableContainer from "../components/TableContainer";
import {
  API_CALL,
  defaultParamObj,
  FORM_DATA_API_CALL,
  RESOURCE_SERVER,
  useGetListApi,
} from "../utils/network";
import ConfirmPopup from "../components/ConfirmPopup";
import Upload from "antd/lib/upload/Upload";
import FormPopup from "../components/FormPopup";

const resourceColumns = [
  { title: "Resource Title", dataIndex: "title", key: "title" },
  {
    title: "Resource File",
    dataIndex: "filePath",
    key: "filePath",
    render: (f = "") => (
      <a href={f} target="_blank" rel="noreferrer">
        {`${f.slice(0, 12)}...${f.slice(-9)}`}
      </a>
    ),
  },
];

const ResourceTableView = ({ course = {} }) => {
  const [selectedResource, setSelectedResource] = useState({});
  const { getList, list, loading, paramObj } = useGetListApi({
    url: RESOURCE_SERVER + "getAll/" + course._id,
    params: { ...defaultParamObj, limit: 2 },
  });

  const dataHandler = (type = "") => {
    setSelectedResource({ ...selectedResource, loading: true });
    if (type === "delete") {
      API_CALL.delete(
        RESOURCE_SERVER + `${course._id}/${selectedResource.deleteId}/0`
      )
        .then((res) => {
          if (res.status === 200) {
            if (list.length > 1 || paramObj.page === 1) getList();
            else getList({ ...paramObj, page: paramObj.page - 1 });
            message.success("Resource deleted successfully");
          }
          setSelectedResource({});
        })
        .catch((err) => {
          console.log(err);
          setSelectedResource({});
        });
    } else if (type === "create" || type === "update") {
      if (
        (type === "create" && !selectedResource.file) ||
        !selectedResource.title
      ) {
        setSelectedResource({ ...selectedResource, showError: true });
      } else {
        const bodyFormData = new FormData();
        if (selectedResource.file)
          bodyFormData.append("file", selectedResource.file);
        bodyFormData.append("title", selectedResource.title);
        const apiEndUrl = type === "create" ? course._id : selectedResource._id;
        FORM_DATA_API_CALL({
          method: type === "create" ? "post" : "put",
          url: RESOURCE_SERVER + `${type}/${apiEndUrl}`,
          data: bodyFormData,
        })
          .then((res) => {
            if (res.status === 200) {
              getList();
              message.success("Resource created successfully");
            }
            setSelectedResource({});
          })
          .catch((err) => {
            console.log(err);
            setSelectedResource({});
          });
      }
    }
  };

  return (
    <>
      {selectedResource.deleteId && (
        <ConfirmPopup
          type="resource"
          selected={selectedResource}
          setSelected={setSelectedResource}
          dataHandler={dataHandler}
        />
      )}
      {(selectedResource.new || selectedResource._id) && (
        <FormPopup
          type="Resource"
          selected={selectedResource}
          setSelected={setSelectedResource}
          dataHandler={dataHandler}
          formItems={[
            {
              label: "Resource File",
              value: "file",
              errorText: selectedResource.filePath
                ? " "
                : "Please upload a resource file",
              custom: (
                <Upload
                  listType="picture-card"
                  customRequest={({ file }) =>
                    setSelectedResource({ ...selectedResource, file })
                  }
                  fileList={
                    selectedResource.filePath
                      ? [
                          {
                            url: selectedResource.filePath,
                            uid: "-1" + course._id,
                            status: "done",
                            name:
                              selectedResource.filePath.split?.("/")[
                                selectedResource.filePath.split("/").length - 1
                              ] || "",
                          },
                        ]
                      : selectedResource.file
                      ? [selectedResource.file]
                      : []
                  }
                  onRemove={() =>
                    setSelectedResource({
                      ...selectedResource,
                      file: null,
                      filePath: "",
                    })
                  }
                  maxCount={1}
                  multiple={false}
                  disabled={selectedResource.loading || selectedResource.view}
                >
                  {!selectedResource.file && !selectedResource.filePath && (
                    <div>
                      <span style={{ fontSize: 22 }}>+</span>
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              ),
            },
            {
              label: "Resource Title",
              value: "title",
              errorText: "Please enter the resource title",
              placeholder: "Enter resource title",
            },
          ]}
        />
      )}
      <TableContainer
        type="Resource"
        getList={getList}
        loading={loading}
        paramObj={paramObj}
        setSelected={(resource) =>
          resource._id
            ? setSelectedResource({ ...resource, uneditable: true })
            : setSelectedResource(resource)
        }
        columns={resourceColumns}
        dataSource={list}
        subTable
      />
    </>
  );
};

export default ResourceTableView;
