import React from "react";
import { Table, Skeleton, Button } from "antd";
import { AiFillEye } from "react-icons/ai";
import TableHeader from "./TableHeader";

export default function TableView({
  loading = false,
  type = "",
  paramObj = {},
  columns = [],
  list = [],
  getList = () => {},
  setSelected = () => {},
  noAction = false,
  searchFor = null,
  filterFor = null,
  filterOptions = [],
  ...props
}) {
  const updatedColumns = [
    {
      title: "S. no.",
      key: "sno",
      width: 80,
      render: (_, r, i) => (paramObj.page - 1) * paramObj.limit + i + 1,
    },
    ...columns,
    ...(noAction
      ? []
      : [
          {
            title: "Action",
            key: "action",
            render: (_, item) => (
              <Button
                type="primary"
                shape="circle"
                title={"View " + type}
                onClick={() => setSelected({ ...item, view: true })}
                size="middle"
              >
                <AiFillEye style={{ marginBottom: -1.5 }} />
              </Button>
            ),
          },
        ]),
  ];

  return (
    <div className="table-view">
      <TableHeader
        type={type}
        paramObj={paramObj}
        getList={getList}
        searchFor={searchFor}
        filterFor={filterFor}
        filterOptions={filterOptions}
        {...props}
      />
      <Table
        {...props}
        bordered
        scroll={{ x: window.innerWidth < 800 ? true : false }}
        bodyStyle={{ backgroundColor: "#06040f" }}
        rowClassName="data-table-row"
        // locale={{
        //   emptyText: (
        //     <Empty
        //       image={Empty.PRESENTED_IMAGE_SIMPLE}
        //       description={
        //         <b>
        //           Currently there are no{" "}
        //           {type?.toLowerCase()?.replace("all ", "")}s
        //         </b>
        //       }
        //     />
        //   ),
        // }}
        // expandRowByClick
        // onRow={(record) => ({ onClick: () => setSelected(record) })}
        onRow={(record) => ({
          className:
            type === "Pending Approval Bucks" && record.updateCount > 0
              ? "table-highlighted-row"
              : "",
        })}
        rowKey={(data) => data._id}
        pagination={{
          total: paramObj.total || 1,
          current: paramObj.page,
          pageSize: paramObj.limit,
          hideOnSinglePage: true,
          onChange: (page, limit) => getList({ ...paramObj, page, limit }),
          showTotal: (total) => `Total ${total} Bucks`,
          style: { marginBottom: 0, color: "#fff" },
        }}
        columns={
          loading
            ? updatedColumns.map((column) => ({
                ...column,
                render: () => (
                  <Skeleton key={column.title} round active paragraph={false} />
                ),
              }))
            : updatedColumns
        }
        dataSource={
          loading
            ? [...Array(paramObj.limit || 10)].map((_, index) => ({
                _id: `key${index}`,
              }))
            : list
        }
      />
    </div>
  );
}
