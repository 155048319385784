import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Typography, message } from "antd";
import { FaRegUser } from "react-icons/fa";
import { BiLock } from "react-icons/bi";

import { MainContext } from "../utils/context";
import { API_CALL_WITHOUT_TOKEN, USER_SERVER } from "../utils/network";

const { Title } = Typography;

function LoginPage(props) {
  const { setContext } = React.useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const loginHandler = (values = {}) => {
    setLoading(true);
    API_CALL_WITHOUT_TOKEN.post(USER_SERVER + "login", {
      email: values.email,
      password: values.password,
    })
      .then((response) => {
        if (response.data.data?.token) {
          setContext("token_object", response.data.data);
          message.success("Logged in successfully");
        } else setFormErrorMessage("Check out your Account or Password again");
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="form-wrapper">
      <Title level={2}>Sign In</Title>
      <Form
        layout="vertical"
        onFinish={loginHandler}
        initialValues={{ remember: true }}
        style={{ width: "100%", marginTop: 10 }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            prefix={<FaRegUser style={{ marginRight: 10, fontSize: "1rem" }} />}
            placeholder="Enter your email"
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<BiLock style={{ marginRight: 10, fontSize: "1.2rem" }} />}
            placeholder="Enter your password"
            type="password"
          />
        </Form.Item>
        {formErrorMessage && (
          <label>
            <p
              style={{
                color: "#ff0000bf",
                fontSize: "0.7rem",
                border: "1px solid",
                padding: "1rem",
                borderRadius: "10px",
              }}
            >
              {formErrorMessage}
            </p>
          </label>
        )}
        <Form.Item style={{ marginBottom: 5 }}>
          <Checkbox defaultChecked>Remember me</Checkbox>
          <a href="#" style={{ float: "right" }}>
            Forgot Password?
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            style={{ margin: "10px 0", borderRadius: 6 }}
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginPage;
