import React, { useEffect, useState } from "react";
import { Avatar, message, Modal, Select } from "antd";
import { BsImages } from "react-icons/bs";

import {
  API_CALL,
  IMAGE_BASE_URL,
  COLLECTION_SERVER,
  defaultParamObj,
} from "../utils/network";
import TableView from "../components/TableView";
import TabsContainer from "../components/TabsContainer";
import ImageView from "../components/ImageView";
import DrawerView from "../components/DrawerView";

const columns = [
  {
    title: "Image",
    dataIndex: "ipfsHash",
    key: "ipfsHash",
    width: 80,
    align: "center",
    render: (imgHash) =>
      imgHash ? (
        <ImageView src={IMAGE_BASE_URL + imgHash} />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Creator",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
];

export default function Collection() {
  const [refetch, setRefetch] = useState("");
  const [selected, setSelected] = useState({});

  const dataHandler = (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      API_CALL.put(COLLECTION_SERVER + "updateFeaturedCollectionStatus", {
        collectionId: selected._id,
        featureInMarketPlace: selected.featureInMarketPlace,
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(selected.type, "collection refetch");
            setRefetch(selected.type);
            let msg = "removed from";
            if (selected.featureInMarketPlace) msg = "added to";
            message.success(
              "Collection " + msg + " featured marketplace successfully !"
            );
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selected?._id && !selected.view && (
        <Modal
          okText="Yes"
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={<h3>Update Featured Status</h3>}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <b>{selected.featureInMarketPlace ? "add" : "remove"}</b> the
            selected collection {selected.featureInMarketPlace ? "to" : "from"}{" "}
            the featured market place ?
          </h3>
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            {
              label: "Image",
              value: IMAGE_BASE_URL + selected.ipfsHash,
              image: true,
              fullWidth: true,
            },
            { label: "Title", value: selected.title },
            { label: "Creator", value: selected.walletId },
            { label: "Type", value: selected.type },
            {
              label: "Available in Marketplace",
              value: selected.featureInMarketPlace ? "Yes" : "No",
            },
            {
              label: "Description",
              value: selected.description,
              multiline: true,
            },
          ]}
          onClose={() => setSelected({})}
          title="Collection"
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "All",
            content: (
              <CollectionView
                type="All"
                setSelected={setSelected}
                refetch={refetch !== ""}
                setRefetch={setRefetch}
              />
            ),
          },
          {
            label: "Starstake",
            content: (
              <CollectionView
                type="Starstake"
                setSelected={setSelected}
                refetch={refetch === "starstake"}
                setRefetch={setRefetch}
              />
            ),
          },
          {
            label: "Standard",
            content: (
              <CollectionView
                type="Standard"
                setSelected={setSelected}
                refetch={refetch === "standard"}
                setRefetch={setRefetch}
              />
            ),
          },
          {
            label: "Limited",
            content: (
              <CollectionView
                type="Limited"
                setSelected={setSelected}
                refetch={refetch === "limited"}
                setRefetch={setRefetch}
              />
            ),
          },
          // {
          //   label: "Featured",
          //   content: (
          //     <CollectionView
          //       type="Featured"
          //       defaultParams={{
          //         ...defaultParamObj,
          //         featureInMarketPlace: true,
          //       }}
          //       setSelected={setSelected}
          //       refetch={refetch !== ""}
          //       setRefetch={setRefetch}
          //     />
          //   ),
          // },
        ]}
      />
    </>
  );
}

const CollectionView = ({
  type = "",
  defaultParams = defaultParamObj,
  setSelected = () => {},
  refetch = false,
  setRefetch = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParams);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    getCollections();
  }, []);
  useEffect(() => {
    getCollections(paramObj, refetch);
  }, [refetch]);

  const getCollections = (params = paramObj, fetch = true) => {
    if (!fetch || loading) return;
    setLoading(true);
    if (params.total) delete params.total;
    params = { ...params, type: type.toLowerCase() };
    setParamObj(params);
    API_CALL.get(COLLECTION_SERVER + "getAll", { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setCollections(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
          setRefetch("");
        } else setCollections([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <TableView
      type={type + " Collection"}
      loading={loading}
      paramObj={paramObj}
      list={collections}
      getList={getCollections}
      setSelected={setSelected}
      columns={[
        ...columns,
        // {
        //   title: "Featured",
        //   dataIndex: "featureInMarketPlace",
        //   key: "featureInMarketPlace",
        //   render: (featured, collection) => (
        //     <Select
        //       placeholder="Featured"
        //       value={featured}
        //       onChange={(featureInMarketPlace) =>
        //         setSelected({ ...collection, featureInMarketPlace })
        //       }
        //       style={{ width: 120, textAlign: "left" }}
        //     >
        //       <Select.Option value={true}>Yes</Select.Option>
        //       <Select.Option value={false}>No</Select.Option>
        //     </Select>
        //   ),
        // },
      ]}
    />
  );
};
