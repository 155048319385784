import React from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { Link } from "react-router-dom";

import LOGO from "../logo.png";
import { MainContext } from "../utils/context";
import { API_CALL, USER_SERVER } from "../utils/network";

export default function Header() {
  const { getContext, clearContext } = React.useContext(MainContext);

  const logoutHandler = () => {
    API_CALL.get(USER_SERVER + "logout")
      .then((res) => {
        console.log(res);
        window.location.reload();
        clearContext();
      })
      .catch((err) => {
        console.log(err);
        window.location.reload();
        clearContext();
      });
  };

  return (
    <nav className="header-container">
      <Link className="header-logo" to="/">
        <img alt="logo" src={LOGO} />
      </Link>
      {getContext("token_object") && (
        <div title="Logout" onClick={logoutHandler} className="header-logout">
          Admin <BiLogOutCircle style={{ margin: "0 0.5rem" }} />
        </div>
      )}
    </nav>
  );
}
