import React, { createContext } from "react";
import { useEffect } from "react";
import { API_CALL, USER_SERVER } from "./network";

const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [state, setState] = React.useState(
    sessionStorage.getItem("local_state")
      ? JSON.parse(sessionStorage.getItem("local_state"))
      : {}
  );

  useEffect(() => {
    // state.token_object
    if (false)
      API_CALL.get(USER_SERVER + "auth")
        .then((res) => {
          if (res.token) {
            setState({ ...state, token_object: res.data });
            sessionStorage.setItem("token_object", JSON.stringify(res.data));
            sessionStorage.setItem(
              "local_state",
              JSON.stringify({ ...state, token_object: res.data })
            );
          }
        })
        .catch((err) => console.log(err));
  }, []);

  const setContext = (key, value) => {
    const newState = { ...state, [key]: value };
    sessionStorage.setItem("local_state", JSON.stringify(newState));
    sessionStorage.setItem(key, JSON.stringify(value));
    setState(newState);
  };

  const getContext = (key) => state[key];

  const clearContext = () => {
    sessionStorage.clear();
    setState({});
  };

  return (
    <MainContext.Provider value={{ setContext, getContext, clearContext }}>
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
