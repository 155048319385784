import React, { useState } from "react";
import { Button, Card, DatePicker, Form, Input } from "antd";

import {
  FANSCORE_SERVER,
  defaultParamObj,
  useGetListApi,
} from "../utils/network";
import TableView from "../components/TableView";
import DrawerView from "../components/DrawerView";

import { ReactComponent as FanScoreIcon } from "../fanscore-icon.svg";

const columns = [
  {
    title: "Fan Wallet",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  {
    title: "Star Wallet",
    dataIndex: "starWalletId",
    key: "starWalletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  {
    title: "Score Type",
    dataIndex: "scoreType",
    key: "scoreType",
    render: (text = "") => (
      <span style={{ textTransform: "capitalize" }}>
        {text.replaceAll("_", " ")}
      </span>
    ),
  },
  {
    title: "Activity Name",
    dataIndex: "activityName",
    key: "activityName",
    render: (text = "") => (
      <span style={{ textTransform: "capitalize" }}>
        {text.replaceAll("_", " ")}
      </span>
    ),
  },
  {
    title: "Score Earned",
    dataIndex: "pointsEarned",
    key: "pointsEarned",
    render: (text = 0) => (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <FanScoreIcon style={{ width: 20 }} /> {text}
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date = "") => date?.slice?.(0, 10),
  },
];

function FanScore() {
  const [showForm, setShowForm] = useState(true);
  const [selected, setSelected] = useState({});
  const { getList, list, loading, paramObj } = useGetListApi({
    url: FANSCORE_SERVER + "getAll",
    initiateOnLoad: false,
  });

  const getFanScoreList = ({ starWalletId = "", fanWalletId = "" }) => {
    setShowForm(false);
    getList({ ...defaultParamObj, starWalletId, fanWalletId });
  };

  const dateRangeFilterHandler = ([startDate, endDate]) => {
    const fromDate = startDate.format("YYYY-MM-DD");
    const toDate = endDate.format("YYYY-MM-DD");
    getList({ ...paramObj, fromDate, toDate });
  };

  return (
    <>
      <Card
        bordered
        className="content-card"
        bodyStyle={{ padding: showForm ? 24 : 0 }}
      >
        {showForm ? (
          <>
            <div className="table-header">
              <h3 style={{ marginBottom: 0 }}>Fan Score Management</h3>
            </div>
            <div style={{ padding: "20px 0" }}>
              <h5>Please enter Star & Fan Wallet Address</h5>
              <Form layout="inline" onFinish={getFanScoreList}>
                <Form.Item
                  name="starWalletId"
                  label="Star Wallet"
                  rules={[
                    { required: true, message: "Please input star wallet!" },
                  ]}
                >
                  <Input placeholder="Enter or paste star wallet address" />
                </Form.Item>
                <Form.Item
                  name="fanWalletId"
                  label="Fan Wallet"
                  rules={[
                    { required: true, message: "Please input fan wallet!" },
                  ]}
                >
                  <Input placeholder="Enter or paste fan wallet address" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        ) : (
          <TableView
            type="Fan Score"
            getList={getList}
            loading={loading}
            paramObj={paramObj}
            setSelected={(scoreData) => setSelected(scoreData)}
            columns={columns}
            list={list}
            otherFilters={
              <div style={{ marginBottom: 16 }}>
                <DatePicker.RangePicker
                  onChange={dateRangeFilterHandler}
                  disabledDate={(current) => current && current > Date.now()}
                  format="DD MMM YYYY"
                  style={{ marginRight: 16 }}
                />
                <Button type="primary" onClick={() => setShowForm(true)}>
                  Reset
                </Button>
              </div>
            }
          />
        )}
      </Card>
      {selected?._id && selected?.view && (
        <DrawerView
          data={[
            { label: "Fan Wallet", value: selected.walletId },
            { label: "Star Wallet", value: selected.starWalletId },
            {
              label: "Points Earned",
              custom: (
                <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                  <FanScoreIcon style={{ width: 20 }} /> {selected.pointsEarned}
                </div>
              ),
            },
            {
              label: "Score Type",
              custom: (
                <span style={{ textTransform: "capitalize" }}>
                  {(selected.scoreType || "").replaceAll("_", " ")}
                </span>
              ),
            },
            {
              label: "Activity Name",
              custom: (
                <span style={{ textTransform: "capitalize" }}>
                  {(selected.activityName || "").replaceAll("_", " ")}
                </span>
              ),
            },
            { label: "Date", value: (selected.createdAt || "").slice(0, 10) },
            // { label: "Custom", custom: selected.customData || "-" },
          ]}
          title="Fan Score"
          onClose={() => setSelected({})}
        />
      )}
    </>
  );
}

export default FanScore;
