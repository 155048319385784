import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { routes } from "../utils/routes";

export default function Sidebar() {
  const { pathname = "" } = useLocation();

  return (
    <div className="sidebar-container">
      <Menu
        mode="inline"
        selectedKeys={[pathname]}
        items={routes.map((route) => ({
          key: route.path,
          label: (
            <Link to={route.path}>
              <span style={{ fontSize: "1.2em" }}>{route.label}</span>
            </Link>
          ),
        }))}
      />
    </div>
  );
}
