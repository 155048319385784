import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Select } from "antd";

import {
  ACCESS_REQUEST_SERVER,
  API_CALL,
  defaultParamObj,
} from "../utils/network";
import TableView from "../components/TableView";
import TabsContainer from "../components/TabsContainer";
import DrawerView from "../components/DrawerView";

const columns = [
  {
    title: "Walled Id",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  { title: "Nick Name", dataIndex: "nickName", key: "nickName" },
  {
    title: "Claimed Access Pass",
    dataIndex: "accessNftClaimed",
    key: "accessNftClaimed",
    render: (accessNftClaimed) => (accessNftClaimed ? "Yes" : "No"),
  },
  {
    title: "Invited By",
    dataIndex: "invitedBy",
    key: "invitedBy",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Submited On",
    dataIndex: "submitedOn",
    key: "submitedOn",
    render: (date) => date?.slice?.(0, 10),
  },
];

export default function CreatorHubAccess() {
  const [refetch, setRefetch] = useState("");
  const [selected, setSelected] = useState({});
  const [rejectReason, setRejectReason] = useState("");

  const dataHandler = (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      const payload =
        selected.status === "rejected"
          ? { status: selected.status, rejectReason }
          : { status: selected.status };
      API_CALL.put(ACCESS_REQUEST_SERVER + selected._id, payload)
        .then((res) => {
          if (res.status === 200) {
            if (selected.status === "rejected") {
              setRefetch("rejected");
              message.success(
                "Creatorhub access request rejected successfully"
              );
            } else {
              setRefetch("approved");
              message.success(
                "Creatorhub access request approved successfully"
              );
            }
          }
          setSelected({});
          setRejectReason("");
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selected?._id && !selected.view && (
        <Modal
          okText={
            "Yes, " + (selected.status === "rejected" ? "Reject" : "Approve")
          }
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={<h3>Update CreatorHub Access Request Status</h3>}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          okButtonProps={{
            disabled: selected.status === "rejected" && !rejectReason,
          }}
          maskStyle={{ opacity: 1 }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <b>{selected.status === "rejected" ? "reject" : "approve"}</b> the
            selected creatorhub access request ?
          </h3>
          {selected.status === "rejected" && (
            <Form.Item
              labelCol={{ span: 24, style: { padding: 0 } }}
              label={
                <span style={{ width: "100%", fontSize: 12 }}>
                  Reason for rejection <span style={{ color: "red" }}>*</span>
                </span>
              }
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                style={{ width: "100%" }}
                placeholder="Enter rejection reason here"
              />
            </Form.Item>
          )}
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            {
              label: "KYC Image",
              value: selected.kycDoc,
              image: true,
              fullWidth: true,
            },
            { label: "Wallet Id", value: selected.walletId, fullWidth: true },
            { label: "Nick Name", value: selected.nickName },
            {
              label: "Claimed Access Pass",
              value: selected.accessNftClaimed ? "Yes" : "No",
            },
            {
              label: "Submited On",
              value: selected.submitedOn?.slice?.(0, 10),
            },
            { label: "Status", value: selected.status },
            { label: "Invited By", value: selected.invitedBy },
            {
              label: "QnA",
              value: selected.qnA
                ? Object.values(selected.qnA).map((value, idx) => (
                    <div key={idx} style={{ marginBottom: "1rem" }}>
                      <span
                        style={{ color: "#555", textTransform: "capitalize" }}
                      >
                        Q. {value.question?.replace?.(/([0-9A-Z])/g, " $&")}
                      </span>
                      <br />
                      A.{" "}
                      {typeof value.answer !== "string"
                        ? value.answer?.join?.(", ")
                        : value.answer}
                      {/* {value.type === "file_url" ? (
                        <ImageView
                          src={value.answer}
                          style={{ height: 100, width: 100 }}
                        />
                      ) : (
                        <span style={{ fontWeight: "500" }}>
                          {value.type === "choices"
                            ? value.answer?.labels.join(", ")
                            : value.type === "choice"
                            ? value.answer?.label
                            : value.type === "boolean"
                            ? value.answer
                              ? "Yes"
                              : "No"
                            : value.answer}
                        </span>
                      )} */}
                      <br />
                    </div>
                  ))
                : "",
              multiline: true,
            },
          ]}
          onClose={() => setSelected({})}
          title="CreatorHub Access Request"
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "Pending",
            content: (
              <AccessRequestView
                type="Pending"
                setSelected={setSelected}
                refetchRequests={refetch !== ""}
                setRefetchRequests={setRefetch}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, request) => (
                      <Select
                        placeholder="Select Status"
                        value={status}
                        onChange={(newStatus) =>
                          setSelected({ ...request, status: newStatus })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="pending"
                        >
                          Pending
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                        {/* <Select.Option value="onhold">On Hold</Select.Option> */}
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Rejected",
            content: (
              <AccessRequestView
                type="Rejected"
                setSelected={setSelected}
                refetchRequests={refetch !== ""}
                setRefetchRequests={setRefetch}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, request) => (
                      <Select
                        placeholder="Select Status"
                        value={status}
                        onChange={(newStatus) =>
                          setSelected({ ...request, status: newStatus })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="rejected"
                        >
                          Rejected
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        {/* <Select.Option value="onhold">On Hold</Select.Option> */}
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Pre Approved",
            content: (
              <AccessRequestView
                type="Pre-Approved"
                setSelected={setSelected}
                refetchRequests={false}
                setRefetchRequests={setRefetch}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, request) => (
                      <Select
                        placeholder="Select Status"
                        value={status}
                        onChange={(newStatus) =>
                          setSelected({ ...request, status: newStatus })
                        }
                        style={{ width: 160, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="pre-approved"
                        >
                          Pre-Approved
                        </Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Approved",
            content: (
              <AccessRequestView
                type="Approved"
                setSelected={setSelected}
                refetchRequests={refetch !== ""}
                setRefetchRequests={setRefetch}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, request) => (
                      <Select
                        placeholder="Select Status"
                        value={status}
                        onChange={(newStatus) =>
                          setSelected({ ...request, status: newStatus })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="approved"
                        >
                          Approved
                        </Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                        {/* <Select.Option value="onhold">On Hold</Select.Option> */}
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "On Hold",
            content: (
              <AccessRequestView
                type="On Hold"
                setSelected={setSelected}
                refetchRequests={refetch !== ""}
                setRefetchRequests={setRefetch}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, request) => (
                      <Select
                        placeholder="Select Status"
                        value={status}
                        onChange={(newStatus) =>
                          setSelected({ ...request, status: newStatus })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="onhold"
                        >
                          On Hold
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </>
  );
}

const AccessRequestView = ({
  type = "",
  columns = [],
  setSelected = () => {},
  refetchRequests = false,
  setRefetchRequests = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParamObj);
  const [bucks, setBucks] = useState([]);

  useEffect(() => {
    getRequests();
  }, []);
  useEffect(() => {
    getRequests(paramObj, refetchRequests);
  }, [refetchRequests]);

  const getRequests = (params = paramObj, fetch = true) => {
    if (!fetch || loading) return;
    setLoading(true);
    if (params.total) delete params.total;
    setParamObj(params);
    API_CALL.get(
      ACCESS_REQUEST_SERVER + "getAll/" + type.replace(" ", "").toLowerCase(),
      { params }
    )
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setBucks(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
          setRefetchRequests("");
        } else setBucks([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <TableView
      type={type + " CreatorHub Access Request"}
      columns={columns}
      list={bucks}
      loading={loading}
      paramObj={paramObj}
      setSelected={setSelected}
      getList={getRequests}
    />
  );
};
