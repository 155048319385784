import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import "antd/dist/antd.min.css";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MainContextProvider } from "./utils/context";

ConfigProvider.config({ theme: { primaryColor: "#5a52ff" } });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    autoInsertSpaceInButton
    form={{ requiredMark: false }}
    componentSize="large"
    getPopupContainer={(node) => {
      if (node) return node.parentNode;
      return document.body;
    }}
  >
    <React.StrictMode>
      <MainContextProvider>
        <App />
      </MainContextProvider>
    </React.StrictMode>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
