import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineUser } from "react-icons/ai";

import { AGENT_SERVER, API_CALL, defaultParamObj } from "../utils/network";
import FormPopup from "../components/FormPopup";
import TableView from "../components/TableView";
import TabsContainer from "../components/TabsContainer";
import TableContainer from "../components/TableContainer";
import DrawerView from "../components/DrawerView";
import ImageView from "../components/ImageView";

const commonColumns = [
  {
    title: "Wallet Address",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  {
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
    render: (fn, a) => fn + " " + a.lastName,
  },
  { title: "Email", dataIndex: "email", key: "email" },
];

const agentColumns = [
  {
    title: "Photo",
    dataIndex: "profilePicture",
    key: "profilePicture",
    width: 60,
    render: (profilePicture) =>
      profilePicture ? (
        <ImageView
          src={profilePicture}
          style={{ borderRadius: "50%", height: 45, width: 45 }}
        />
      ) : (
        <Avatar icon={<AiOutlineUser />} size={45} />
      ),
  },
  ...commonColumns,
  {
    title: "Role",
    dataIndex: "userType",
    key: "userType",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
];

const creditRequestColumns = [
  ...commonColumns,
  {
    title: "Available Credit",
    key: "availableCredit",
    dataIndex: "availableCredit",
    align: "center",
    render: (credit) => (
      <span style={{ fontWeight: "bold" }}>{credit || 0}</span>
    ),
  },
  {
    title: "Requested Credit",
    key: "requestedCredit",
    dataIndex: "requestedCredit",
    align: "center",
    render: (credit) => (
      <span style={{ fontWeight: "bold" }}>{credit || 0}</span>
    ),
  },
];

const tagColors = {
  awaitingApproval: "orange",
  approved: "green",
  rejected: "red",
};

export default function Agent() {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParamObj);
  const [agents, setAgents] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedRequest, setSelectedRequest] = useState({});
  const [refetch, setRefetch] = useState("");
  const [agentWalletId, setAgentWalletId] = useState("");

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = (params = paramObj) => {
    setLoading(true);
    if (params.total) delete params.total;
    setParamObj(params);
    API_CALL.get(AGENT_SERVER + "getAll", { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setAgents(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const dataHandler = (type = "") => {
    if (type === "delete") {
      setSelected({ ...selected, loading: true });
      API_CALL.delete(AGENT_SERVER + `${selected._id}/0`)
        .then((res) => {
          if (res.status === 200) {
            setAgents(
              agents.filter((agent) => agent._id !== selected.deleteId)
            );
            message.error("Agent deleted successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    } else if (type === "create" || type === "update") {
      if (
        !selected.firstName ||
        !selected.lastName ||
        !selected.email ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email) ||
        !selected.walletAddress ||
        !selected.userType
      ) {
        setSelected({ ...selected, showError: true });
        message.error("Please fill all the fields correctly !");
      } else {
        setSelected({ ...selected, loading: true });
        let payload = { ...selected };
        if (payload.new) delete payload.new;
        if (payload.view) delete payload.view;
        if (type === "create")
          API_CALL.post(AGENT_SERVER + "create", payload)
            .then((res) => {
              if (res.status === 200) {
                getAgents();
                message.success("Agent created successfully");
              }
              setSelected({});
            })
            .catch((err) => {
              console.log(err);
              setSelected({});
            });
        else if (type === "update")
          API_CALL.put(AGENT_SERVER + `update/${selected._id}`, payload)
            .then((res) => {
              if (res.status === 200) {
                getAgents();
                message.success("Agent updated successfully");
              }
              setSelected({});
            })
            .catch((err) => {
              console.log(err);
              setSelected({});
            });
      }
    } else if (type === "credit") {
      setSelectedRequest({ ...selectedRequest, loading: true });
      let payload = {
        credit: parseInt(selectedRequest.creditUpdateBy),
        walletId: selectedRequest.walletId,
      };
      if (selectedRequest.requestedCredit)
        payload.requestId = selectedRequest._id;
      API_CALL.put(AGENT_SERVER + "addCredit", payload)
        .then((res) => {
          if (res.status === 200) {
            setRefetch("pending");
            getAgents();
            message.success("Agent credits updated successfully");
          }
          setSelectedRequest({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selectedRequest._id && (
        <Modal
          okText="Save"
          onOk={() => dataHandler("credit")}
          onCancel={() => setSelectedRequest({})}
          title="Update Agent Credits"
          confirmLoading={selectedRequest.loading}
          open
          centered
          maskClosable={false}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
        >
          <h3 style={{ color: "#333", margin: "auto" }}>
            Total available credits:
            <b> {selectedRequest.availableCredit || 0}</b>
          </h3>
          {selectedRequest.requestedCredit && (
            <h3 style={{ color: "#333", margin: "auto" }}>
              Requested credits:
              <b> {selectedRequest.requestedCredit}</b>
            </h3>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <Button
              onClick={() =>
                setSelectedRequest({
                  ...selectedRequest,
                  creditUpdateBy: selectedRequest.creditUpdateBy
                    ? parseInt(selectedRequest.creditUpdateBy) - 1
                    : 1,
                })
              }
              disabled={
                !selectedRequest.creditUpdateBy ||
                selectedRequest.creditUpdateBy <= 1
              }
              style={{ marginRight: "0.6rem", padding: 0 }}
              icon={<AiOutlineMinus />}
              type="primary"
              shape="circle"
              title="Add Credit"
              size="middle"
            ></Button>
            <Input
              onChange={(e) => {
                const creditUpdateBy = e.target.value;
                if (!parseInt(creditUpdateBy))
                  setSelectedRequest({ ...selectedRequest, creditUpdateBy: 1 });
                else if (parseInt(creditUpdateBy).toString() === creditUpdateBy)
                  setSelectedRequest({ ...selectedRequest, creditUpdateBy });
              }}
              value={selectedRequest.creditUpdateBy || 1}
              style={{
                boxShadow: "1px 1px 3px #ddd, -1px -1px 3px #ddd",
                width: 45,
                padding: "0.8rem 0",
                textAlign: "center",
              }}
              maxLength={2}
            />
            <Button
              onClick={() =>
                setSelectedRequest({
                  ...selectedRequest,
                  creditUpdateBy: selectedRequest.creditUpdateBy
                    ? parseInt(selectedRequest.creditUpdateBy) + 1
                    : 2,
                })
              }
              disabled={
                selectedRequest.creditUpdateBy >=
                selectedRequest.requestedCredit
              }
              style={{ marginLeft: "0.6rem", padding: 0 }}
              icon={<AiOutlinePlus />}
              type="primary"
              shape="circle"
              title="Add Credit"
              size="middle"
            ></Button>
          </div>
        </Modal>
      )}
      {(selected.new || selected._id) && (
        <FormPopup
          type="Agent"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
          formItems={[
            {
              label: "Role",
              value: "userType",
              errorText: "Please select agent role",
              placeholder: "Select role",
              options: [{ label: "Agent", value: "agent" }],
            },
            {
              label: "First Name",
              value: "firstName",
              errorText: "Please enter the agent first name",
              placeholder: "Enter first name",
            },
            {
              label: "Last Name",
              value: "lastName",
              errorText: "Please enter the last name",
              placeholder: "Enter last name",
            },
            {
              label: "Email Address",
              value: "email",
              errorText: "Please enter the agent email",
              placeholder: "Enter email address",
              customErrorText: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email)
                ? ""
                : "Please enter valid email address",
            },
            {
              label: "Wallet Address",
              value: "walletAddress",
              errorText: "Please enter the agent wallet address",
              placeholder: "Enter wallet address",
            },
          ]}
        />
      )}
      {agentWalletId !== "" && (
        <AgentStarsView
          agentWalletId={agentWalletId}
          onClose={() => setAgentWalletId("")}
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "Agents",
            content: (
              <Card bordered={false}>
                <TableContainer
                  type="Agent"
                  getList={getAgents}
                  loading={loading}
                  paramObj={paramObj}
                  searchFor="wallet Id, name, email"
                  setSelected={(agent) =>
                    agent._id
                      ? setSelected({
                          ...agent,
                          walletAddress: agent.walletId,
                          uneditable: true,
                        })
                      : setSelected(agent)
                  }
                  columns={[
                    ...agentColumns,
                    {
                      title: "Credit Available",
                      dataIndex: "availableCredit",
                      key: "availableCredit",
                      align: "center",
                      render: (credit, agent) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {credit || 0}
                          </span>
                          <Button
                            onClick={() =>
                              setSelectedRequest({
                                ...agent,
                                creditUpdateBy: 1,
                              })
                            }
                            style={{ marginLeft: "0.2rem", padding: 0 }}
                            icon={
                              <AiOutlinePlus
                                style={{ height: "0.7rem", width: "0.7rem" }}
                              />
                            }
                            type="primary"
                            shape="circle"
                            title="Add Credit"
                            size="small"
                          ></Button>
                        </div>
                      ),
                    },
                    {
                      title: "View Stars",
                      key: "stars",
                      render: (_, agent) => (
                        <Button
                          onClick={() => setAgentWalletId(agent.walletId)}
                          size="small"
                          type="link"
                        >
                          View Stars
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={agents}
                  hideDelete
                />
              </Card>
            ),
          },
          {
            label: "Pending Credit Requests",
            content: (
              <AgentCreditRequestView
                type="Pending"
                refetch={refetch === "pending"}
                setRefetch={setRefetch}
                columns={[
                  ...creditRequestColumns,
                  {
                    title: "Approve Request",
                    key: "approve",
                    align: "center",
                    render: (_, request) => (
                      <Select
                        placeholder="Update Status"
                        value="pending"
                        onChange={() =>
                          setSelectedRequest({
                            ...request,
                            creditUpdateBy: request.requestedCredit,
                          })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="pending"
                        >
                          Pending Approval
                        </Select.Option>
                        <Select.Option value="approved">
                          Approve Credit Request
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </>
  );
}

const AgentCreditRequestView = ({
  type = "",
  columns = [],
  setSelected = () => {},
  refetch = false,
  setRefetch = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParamObj);
  const [creditRequests, setCreditRequests] = useState([]);

  useEffect(() => {
    getCreditRequests(paramObj, refetch);
  }, [refetch]);

  const getCreditRequests = (params = paramObj) => {
    if (loading) return;
    setLoading(true);
    if (params.total) delete params.total;
    setParamObj(params);
    const requestType = type.toLowerCase();
    API_CALL.get(`${AGENT_SERVER}credit/getAll/${requestType}`, { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setCreditRequests(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
          setRefetch("");
        } else setCreditRequests([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <TableView
      type={type + " Credit Requests"}
      columns={columns}
      list={creditRequests}
      loading={loading}
      paramObj={paramObj}
      setSelected={setSelected}
      getList={getCreditRequests}
      noAction
    />
  );
};

const AgentStarsView = ({ agentWalletId = "", onClose = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const [utilizedCredits, setUtilizedCredits] = useState(0);

  useEffect(() => {
    getStars();
  }, []);

  const getStars = () => {
    setLoading(true);
    API_CALL.get(AGENT_SERVER + "getAllStar/" + agentWalletId)
      .then((res) => {
        if (res.status === 200 && res.data?.starArray?.length) {
          setStars(res.data.starArray);
          setUtilizedCredits(res.data.utilizsedCredit);
        } else setStars([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <DrawerView title="View Stars" data={[]} onClose={onClose}>
      <List
        dataSource={stars}
        loading={loading}
        itemLayout="vertical"
        header={
          !loading && (
            <h3>
              Agent has utilized <b>{utilizedCredits}</b> credits.
            </h3>
          )
        }
        renderItem={(star, index) => (
          <List.Item style={{ marginTop: index === 0 ? 0 : 16 }}>
            <List.Item.Meta
              avatar={
                star.profilePicture ? (
                  <ImageView
                    src={star.profilePicture}
                    style={{ borderRadius: "50%", height: 60, width: 60 }}
                  />
                ) : (
                  <Avatar icon={<AiOutlineUser />} size={60} />
                )
              }
              title={
                <>
                  Star #{index + 1} -{" "}
                  <b>
                    {star.firstName || "N/A"} {star.lastName}
                  </b>
                  {star.starConfirmation && (
                    <Tag
                      style={{
                        position: "absolute",
                        right: 0,
                        textTransform: "capitalize",
                      }}
                      color={tagColors[star.starConfirmation]}
                    >
                      {star.starConfirmation &&
                      star.starConfirmation !== "awaitingApproval"
                        ? star.starConfirmation
                        : "Awaiting Approval"}
                    </Tag>
                  )}
                </>
              }
              description={
                <Row>
                  <Col xs={24}>
                    Wallet Id: <b>{star.starWalletId || "~"}</b>
                  </Col>
                  <Col xs={24} lg={12}>
                    Nick Name: <b>{star.nickName || "~"}</b>
                  </Col>
                  <Col xs={24} lg={12} style={{ textAlign: "right" }}>
                    Added On: <b>{star.createdOn?.slice?.(0, 10) || "~"}</b>
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
        locale={{ emptyText: "No stars found" }}
        rowKey={(star) => star._id}
      />
    </DrawerView>
  );
};
