import React, { useEffect, useState } from "react";
import { Card, message, Select } from "antd";

import { API_CALL, defaultParamObj, NOTI_SERVER } from "../utils/network";
import TableContainer from "../components/TableContainer";
import FormPopup from "../components/FormPopup";
import ConfirmPopup from "../components/ConfirmPopup";

const categoryOptions = [
  { value: "starstake", label: "StarStake" },
  { value: "stakehub", label: "Stake Hub" },
  { value: "creatorhub", label: "Creator Hub" },
  { value: "snft", label: "Brand Club" },
  { value: "bucks", label: "Bucks" },
  { value: "accesstoken", label: "Access Token" },
];

const columns = [
  { title: "ID", dataIndex: "_id", key: "_id" },
  { title: "Display As", dataIndex: "displayAs", key: "displayAs" },
  { title: "Type", dataIndex: "notificationType", key: "notificationType" },
  {
    title: "Broadcast Group",
    dataIndex: "broadCastGroup",
    key: "broadCastGroup",
  },
  { title: "Status", dataIndex: "status", key: "status" },
  // {
  //   title: "Message",
  //   dataIndex: "message",
  //   key: "message",
  //   ellipsis: { showTitle: false },
  //   width: 200,
  // },
];

export default function Notification() {
  const [loading, setLoading] = useState(true);
  const [paramObj, setParamObj] = useState(defaultParamObj);
  const [notifications, setNotifications] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = (params = paramObj) => {
    setLoading(true);
    if (params.total) delete params.total;
    setParamObj(params);
    API_CALL.get(NOTI_SERVER + "getAll", { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          const data = res.data[0] || res.data;
          setNotifications(data.data);
          setParamObj({ ...params, total: data.totalCount?.totalRecords });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const dataHandler = (type = "") => {
    if (type === "delete") {
      setSelected({ ...selected, loading: true });
      API_CALL.delete(NOTI_SERVER + selected.deleteId)
        .then((res) => {
          if (res.status === 200) {
            setNotifications(
              notifications.filter((noti) => noti._id !== selected.deleteId)
            );
            message.success("Notification deleted successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    } else if (
      !selected.displayAs ||
      !selected.category ||
      !selected.broadCastGroup ||
      !selected.notificationType ||
      !selected.message
    ) {
      setSelected({ ...selected, showError: true });
    } else {
      let payload = { ...selected };
      delete payload.new;
      delete payload.view;
      if (!payload.email?.length) payload = { ...payload, email: [] };
      if (!payload.walletId?.length) payload = { ...payload, walletId: [] };
      setSelected({ ...selected, loading: true });
      if (type === "create") {
        API_CALL.post(NOTI_SERVER + "create", payload)
          .then((res) => {
            if (res.status === 200) {
              getNotifications();
              message.success("Notification created successfully");
            }
            setSelected({});
          })
          .catch((err) => {
            console.log(err);
            setSelected({});
          });
      } else if (type === "update") {
        API_CALL.put(NOTI_SERVER + selected._id, payload)
          .then((res) => {
            if (res.status === 200) {
              getNotifications();
              message.success("Notification updated successfully");
            }
            setSelected({});
          })
          .catch((err) => {
            console.log(err);
            setSelected({});
          });
      }
    }
  };

  const MultiSelectDropdown = ({ type = "" }) =>
    selected.view ? (
      <h3 style={{ margin: 0, fontSize: 15, marginBottom: "0.2rem" }}>
        {selected[type]?.length ? selected[type]?.toString() : "~"}
      </h3>
    ) : (
      <Select
        mode="tags"
        value={selected[type]}
        onChange={(v) => setSelected({ ...selected, [type]: v })}
        placeholder={"Enter " + type + " addresses"}
        style={{ width: "100%" }}
        tokenSeparators={[","]}
      >
        <Select.Option value={type}>{type}</Select.Option>
      </Select>
    );

  return (
    <>
      {selected.deleteId && (
        <ConfirmPopup
          type="notification"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
        />
      )}
      {(selected.new || selected._id) && (
        <FormPopup
          type="Notification"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
          formItems={[
            {
              label: "Display As",
              value: "displayAs",
              errorText: "Please select display as value !",
              placeholder: "Select display as",
              options: [
                { label: "Success", value: "success" },
                { label: "Warning", value: "warning" },
                { label: "Error", value: "error" },
              ],
            },
            {
              label: "Category",
              value: "category",
              errorText: "Please select category !",
              placeholder: "Select category",
              options: categoryOptions,
            },
            {
              label: "Type",
              value: "notificationType",
              errorText: "Please select notification type !",
              placeholder: "Select type",
              options: [{ label: "In App", value: "inApp" }],
            },
            {
              label: "Broadcast Group",
              value: "broadCastGroup",
              errorText: "Please select broadcast group !",
              placeholder: "Select broadcast group",
              options: [{ label: "All", value: "all" }],
            },
            {
              label: "Email Addresses",
              value: "email",
              errorText: "Please select email address !",
              custom: <MultiSelectDropdown type="email" />,
            },
            {
              label: "Wallet Addresses",
              value: "walletId",
              errorText: "Please select wallet address !",
              custom: <MultiSelectDropdown type="walletId" />,
            },
            {
              label: "Message",
              value: "message",
              errorText: "Please enter the message !",
              placeholder: "Enter message",
              multiline: true,
            },
          ]}
        />
      )}
      <Card bordered className="content-card">
        <TableContainer
          type="Notification"
          getList={getNotifications}
          loading={loading}
          paramObj={paramObj}
          setSelected={setSelected}
          columns={columns}
          dataSource={notifications}
        />
      </Card>
    </>
  );
}
