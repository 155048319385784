import React from "react";
import { Tabs } from "antd";

export default function TabsContainer({ onChange = () => {}, tabs = [] }) {
  return (
    <Tabs className="tabs-container" onChange={onChange} type="card">
      {tabs.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.label}>
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}
