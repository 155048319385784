import React from "react";

function Footer() {
  return (
    <div className="footer-container">
      <span style={{ color: "white", fontSize: "1rem" }}> StarStake Admin</span>
    </div>
  );
}

export default Footer;
