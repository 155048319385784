import React from "react";
import { Upload } from "antd";

import ImageView from "./ImageView";

export default function UploadView({
  accept = "image/*",
  selected = {},
  setSelected = () => {},
  urlKey = "thumbnailPath",
}) {
  return selected.view && accept === "image/*" ? (
    <div
      style={{
        padding: 10,
        marginBottom: 10,
        border: "1px solid #d9d9d9",
        width: "max-content",
      }}
    >
      <ImageView
        src={selected[urlKey] || ""}
        style={{ objectFit: "contain", height: 100, width: 100 }}
      />
    </div>
  ) : (
    <Upload
      accept={accept}
      listType="picture-card"
      customRequest={({ file }) => {
        const reader = new FileReader();
        reader.onload = (e) =>
          setSelected({ ...selected, file: file, url: e.target.result });
        reader.readAsDataURL(file);
      }}
      fileList={
        selected.file || selected[urlKey]
          ? [
              {
                url: selected[urlKey] || selected.url,
                uid: "-1",
                status: "done",
                name: "",
              },
            ]
          : []
      }
      onRemove={() => setSelected({ ...selected, file: null, [urlKey]: "" })}
      showUploadList={{ showPreviewIcon: selected.view }}
      maxCount={1}
      multiple={false}
      disabled={selected.loading || selected.view}
    >
      {!selected.file && !selected[urlKey] && (
        <div>
          <span style={{ fontSize: 22 }}>+</span>
          <div style={{ marginTop: 4 }}>Upload</div>
        </div>
      )}
    </Upload>
  );
}
