import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./utils/styles.css";
import { MainContext } from "./utils/context";
import { routes } from "./utils/routes";
import LoginPage from "./screens/Login";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

function App() {
  const { getContext } = React.useContext(MainContext);

  return (
    <BrowserRouter>
      <div className="app-wrapper">
        <div className="header-wrapper">
          <Header />
        </div>
        {getContext("token_object") ? (
          <div className="main-wrapper">
            <div className="sidebar-wrapper">
              <Sidebar />
            </div>
            <div className="content-wrapper">
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                <Route path="*" element={<Navigate replace to="/banner" />} />
              </Routes>
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "#f0f0f0" }} className="main-wrapper">
            <LoginPage />
          </div>
        )}
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
