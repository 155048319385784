import React, { useState } from "react";
import { Avatar, Card, message } from "antd";
import { BsImages } from "react-icons/bs";

import {
  FORM_DATA_API_CALL,
  IMAGE_PUBLIC_BASE_URL,
  MASTER_DATA_SERVER,
  useGetListApi,
} from "../utils/network";
import TableContainer from "../components/TableContainer";
import ImageView from "../components/ImageView";
import FormPopup from "../components/FormPopup";
import UploadView from "../components/UploadView";

const categoryOptions = [{ value: "BANNER", label: "Banner" }];
const nameOptions = [
  { value: "MARKETHUB_SECTION_1", label: "MarketHub Section 1" },
  { value: "MARKETHUB_SECTION_2", label: "MarketHub Section 2" },
  { value: "STAKEHUB_HOME_1", label: "StakeHub Home 1" },
  { value: "STAKEHUB_HOME_2", label: "StakeHub Home 2" },
  { value: "STAKEHUB_TRAINING", label: "StakeHub Training Center" },
  { value: "STAKEHUB_ACCESS_NFT", label: "StakeHub Access NFT" },
  { value: "STAKEHUB_BRAND_CLUB", label: "StakeHub Brand Club" },
  { value: "CREATORHUB_HOME_1", label: "CreatorHub Home" },
  { value: "CREATORHUB_TRAINING", label: "CreatorHub Training Center" },
  { value: "CREATORHUB_NFT_BUCKS_1", label: "CreatorHub NFT Bucks 1" },
  { value: "CREATORHUB_NFT_BUCKS_2", label: "CreatorHub NFT Bucks 2" },
  { value: "CREATORHUB_STARCONNECT", label: "CreatorHub StarConnect" },
  { value: "CREATORHUB_ACCESS_NFT", label: "CreatorHub Access NFT" },
  { value: "CREATORHUB_BRAND_CLUB_1", label: "CreatorHub Brand Club 1" },
  { value: "CREATORHUB_BRAND_CLUB_2", label: "CreatorHub Brand Club 2" },
  { value: "CREATORHUB_RESOURCES_1", label: "CreatorHub Resources 1" },
  { value: "CREATORHUB_RESOURCES_2", label: "CreatorHub Resources 2" },
  { value: "CREATORHUB_RESOURCES_3", label: "CreatorHub Resources 3" },
  { value: "CREATORHUB_RESOURCES_4", label: "CreatorHub Resources 4" },
  { value: "CREATORHUB_RESOURCES_5", label: "CreatorHub Resources 5" },
  { value: "CREATORHUB_RESOURCES_6", label: "CreatorHub Resources 6" },
];

const columns = [
  {
    title: "Banner Image",
    dataIndex: "value",
    key: "value",
    align: "center",
    render: (value) =>
      value ? (
        <ImageView
          style={{ width: 120, height: 120 }}
          src={IMAGE_PUBLIC_BASE_URL + value.replace("/images-new", "")}
        />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Banner Category",
    dataIndex: "category",
    key: "category",
    render: () => "Banner",
  },
  {
    title: "Banner Section",
    dataIndex: "name",
    key: "name",
    render: (name) => nameOptions.find(({ value }) => value === name)?.label,
  },
];

export default function Banner() {
  const [selected, setSelected] = useState({});
  const { getList, list, loading, paramObj } = useGetListApi({
    url: MASTER_DATA_SERVER + "getAll",
  });

  const dataHandler = (type = "") => {
    if (type === "create" || type === "update") {
      if (
        (type === "create" && !selected.file) ||
        !selected.category ||
        !selected.name
      )
        return setSelected({ ...selected, showError: true });

      setSelected({ ...selected, loading: true });
      const bodyFormData = new FormData();
      if (selected.file) bodyFormData.append("imageFile", selected.file);
      bodyFormData.append("category", selected.category);
      bodyFormData.append("name", selected.name);
      FORM_DATA_API_CALL({
        method: "post",
        url: MASTER_DATA_SERVER + "save-banner",
        data: bodyFormData,
      })
        .then((res) => {
          if (res.status === 200) {
            getList();
            message.success("Banner " + type + "d successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {(selected.new || selected._id) && (
        <FormPopup
          type="Banner"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
          formItems={[
            {
              label: "Banner Image",
              value: "file",
              errorText: selected.value ? " " : "Please upload banner image",
              custom: (
                <UploadView
                  urlKey="imageUrl"
                  selected={selected}
                  setSelected={setSelected}
                />
              ),
            },
            {
              label: "Category",
              value: "category",
              errorText: "Please select banner category",
              placeholder: "Select Category",
              options: categoryOptions,
            },
            {
              label: "Section",
              value: "name",
              errorText: "Please select banner section",
              placeholder: "Select Section",
              options: nameOptions,
            },
          ]}
        />
      )}
      <Card bordered className="content-card">
        <TableContainer
          type="Banner"
          getList={getList}
          loading={loading}
          paramObj={paramObj}
          setSelected={(banner) =>
            banner?.value
              ? setSelected({
                  ...banner,
                  imageUrl: IMAGE_PUBLIC_BASE_URL + banner.value,
                  uneditable: true,
                })
              : setSelected(banner)
          }
          columns={columns}
          dataSource={list}
          hideDelete
        />
      </Card>
    </>
  );
}
