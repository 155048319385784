import React, { useState } from "react";
import { Card, Button, Form, message } from "antd";

import { API_CALL, defaultParamObj, USER_SERVER } from "../utils/network";
import { MainContext } from "../utils/context";
import TableContainer from "../components/TableContainer";
import FormPopup from "../components/FormPopup";

const columns = [
  {
    title: "First Name",
    dataIndex: "name",
    key: "name",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Last Name",
    dataIndex: "lastname",
    key: "lastname",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (t) => (
      <span style={{ textTransform: "capitalize" }}>
        {t?.replace("-", " ")}
      </span>
    ),
  },
];

export default function SubAdmin() {
  const { getContext } = React.useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParamObj);
  const [subAdmins, setSubAdmins] = useState([]);
  const [selected, setSelected] = useState({});
  const tokenObj = getContext("token_object");

  React.useEffect(() => {getSubAdmins()}, []);

  const getSubAdmins = (params = paramObj) => {
    setLoading(true);
    if (params.total) delete params.total;
    setParamObj(params);
    API_CALL.get(USER_SERVER + "getAll/subadmin", { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setSubAdmins(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const createSubAdminHandler = () => {
    if (!tokenObj) return message.error("Something went wrong !");
    if (
      !selected.name ||
      !selected.lastname ||
      !selected.email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email) ||
      !selected.role ||
      !selected.password ||
      selected.password?.length < 6 ||
      selected.password !== selected.confirmPassword
    ) {
      setSelected({ ...selected, showError: true });
      return message.error("Please fill all the fields correctly !");
    }
    setSelected({ ...selected, loading: true });
    let values = { ...selected, image: "", ...tokenObj };
    delete values.confirmPassword;
    API_CALL.post(USER_SERVER + "register", values)
      .then((response) => {
        if (response.data?._id) {
          getSubAdmins();
          message.success("Sub Admin created successfully");
        }
        setSelected({});
      })
      .catch((err) => {
        console.log(err);
        setSelected({});
      });
  };

  return (
    <>
      {(selected.new || selected._id) && (
        <FormPopup
          type="Agent"
          selected={selected}
          setSelected={setSelected}
          dataHandler={createSubAdminHandler}
          formItems={[
            {
              label: "First Name",
              value: "name",
              errorText: "Please enter first name",
              placeholder: "Enter first name",
            },
            {
              label: "Last Name",
              value: "lastname",
              errorText: "Please enter last name",
              placeholder: "Enter last name",
            },
            {
              label: "Email Address",
              value: "email",
              errorText: "Please enter email address",
              placeholder: "Enter email address",
              customErrorText: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email)
                ? ""
                : "Please enter valid email address",
            },
            {
              label: "Role",
              value: "role",
              errorText: "Please select role",
              placeholder: "Select role",
              options: [{ value: "sub-admin", label: "Sub Admin" }],
            },
            ...(selected.view
              ? []
              : [
                  {
                    label: "Set Password",
                    value: "password",
                    errorText: "Please enter password",
                    placeholder: "Enter password",
                    customErrorText:
                      selected.password?.length < 6
                        ? "Password must be atleast 6 characters long"
                        : selected.password !== selected.confirmPassword
                        ? "Passwords do not match !"
                        : "",
                    passwordInput: true,
                    inputProps: { maxLength: 20 },
                  },
                  {
                    label: "Confirm Password",
                    value: "confirmPassword",
                    errorText: "Please enter password",
                    placeholder: "Enter password",
                    passwordInput: true,
                    customErrorText:
                      selected.confirmPassword?.length < 6
                        ? "Password must be atleast 6 characters long"
                        : selected.password !== selected.confirmPassword
                        ? "Passwords do not match !"
                        : "",
                  },
                ]),
          ]}
        />
      )}
      <Card bordered className="content-card">
        <TableContainer
          type="Sub Admin"
          getList={getSubAdmins}
          loading={loading}
          paramObj={paramObj}
          setSelected={(subadmin) =>
            subadmin._id
              ? setSelected({ ...subadmin, uneditable: true })
              : setSelected(subadmin)
          }
          columns={columns}
          dataSource={subAdmins}
          hideDelete
        />
      </Card>
      {/* <Card bordered className="content-card">
        <Form
          form={form}
          name="sub-admin"
          layout="vertical"
          onFinish={createSubAdminHandler}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Create Sub Admin</h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="danger"
                style={{
                  marginRight: "1.2rem",
                  padding: "0.1rem 1.2rem",
                  borderRadius: 5,
                }}
                onClick={() => form.resetFields()}
              >
                Clear
              </Button>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  style={{ padding: "0.1rem 1.5rem", borderRadius: 5 }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
          <Row gutter={[24, 0]} style={{ marginTop: "1rem" }}>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="name"
                label="First Name *"
                rules={[
                  { required: true, message: "Please enter first name!" },
                ]}
              >
                <Input placeholder="Enter name" type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="lastname"
                label="Last Name *"
                rules={[{ required: true, message: "Please enter last name!" }]}
              >
                <Input placeholder="Enter last name" type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="email"
                label="E-mail Address *"
                rules={[
                  { required: true, message: "Please enter e-mail!" },
                  { type: "email", message: "Please enter valid e-mail!" },
                ]}
              >
                <Input placeholder="Enter email" type="email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="role"
                label="Role *"
                rules={[{ required: true, message: "Please select role!" }]}
              >
                <Select
                  options={[{ value: "sub-admin", label: "Sub Admin" }]}
                  placeholder="Select role"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="password"
                label="Set Password *"
                rules={[
                  { required: true, message: "Please enter password!" },
                  { min: 6, message: "Password must be at least 6 characters" },
                ]}
              >
                <Input.Password placeholder="Enter password" type="password" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password *"
                rules={[
                  { required: true, message: "Please confirm password!" },
                  { min: 6, message: "Password must be at least 6 characters" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value)
                        return Promise.resolve();
                      return Promise.reject("Passwords entered do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm password"
                  type="password"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card> */}
    </>
  );
}
