import React, { useEffect, useState } from "react";
import { Avatar, message, Modal, Select } from "antd";
import { BsImages } from "react-icons/bs";

import {
  API_CALL,
  IMAGE_BASE_URL,
  BUNDLE_SERVER,
  defaultParamObj,
} from "../utils/network";
import TableView from "../components/TableView";
import TabsContainer from "../components/TabsContainer";
import ImageView from "../components/ImageView";
import DrawerView from "../components/DrawerView";

const columns = [
  {
    title: "Image",
    dataIndex: "ipfsHash",
    key: "ipfsHash",
    width: 80,
    align: "center",
    render: (imgHash) =>
      imgHash ? (
        <ImageView src={IMAGE_BASE_URL + imgHash} />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Creator",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  { title: "Title", dataIndex: "title", key: "title" },
  {
    title: "Market Value",
    dataIndex: "marketValue",
    key: "marketValue",
    render: (amount) => "$" + amount,
  },
];

export default function Bundle() {
  const [refetch, setRefetch] = useState("");
  const [selected, setSelected] = useState({});

  const dataHandler = (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      API_CALL.put(BUNDLE_SERVER + "updateFeaturedBundleStatus", {
        bundleId: selected._id,
        featureInMarketPlace: selected.featureInMarketPlace,
      })
        .then((res) => {
          if (res.status === 200) {
            setRefetch(selected._id);
            message.success(
              "Bundle " +
                (selected.featureInMarketPlace ? "added to" : "removed from") +
                " featured list successfully"
            );
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selected?._id && !selected.view && (
        <Modal
          okText={"Yes, " + selected.featureInMarketPlace ? "Add" : "Remove"}
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={<h3>Update Featured Status</h3>}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <b>{selected.featureInMarketPlace ? "add" : "remove"}</b> the
            selected bundle from featured market place ?
          </h3>
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            {
              label: "Image",
              value: IMAGE_BASE_URL + selected.ipfsHash,
              image: true,
              fullWidth: true,
            },
            { label: "Title", value: selected.title },
            { label: "Creator", value: selected.walletId },
            { label: "Market Value", value: "$" + selected.marketValue },
            // {
            //   label: "Featured",
            //   value: selected.featureInMarketPlace ? "Yes" : "No",
            // },
            {
              label: "NFTBucks Included",
              value: selected.bucksArray?.map?.((buck) => (
                <span>
                  {buck.buckId || "NA"} - Quantity: {buck.quantity || "NA"}
                  <br />
                </span>
              )),
              multiline: true,
            },
            {
              label: "Description",
              value: selected.description,
              multiline: true,
            },
          ]}
          onClose={() => setSelected({})}
          title="Bundle"
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "All",
            content: (
              <BundleView
                type="All"
                refetch={refetch !== ""}
                setRefetch={setRefetch}
                setSelected={setSelected}
              />
            ),
          },
          // {
          //   label: "Featured",
          //   content: (
          //     <BundleView
          //       type="Featured"
          //       refetch={refetch !== ""}
          //       defaultParams={{
          //         ...defaultParamObj,
          //         featureInMarketPlace: true,
          //       }}
          //       setRefetch={setRefetch}
          //       setSelected={setSelected}
          //     />
          //   ),
          // },
        ]}
      />
    </>
  );
}

const BundleView = ({
  type = "",
  defaultParams = defaultParamObj,
  setSelected = () => {},
  refetch = false,
  setRefetch = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(defaultParams);
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    getBundles();
  }, []);

  useEffect(() => {
    getBundles(paramObj, refetch);
  }, [refetch]);

  const getBundles = (params = paramObj, fetch = true) => {
    if (!fetch || loading) return;
    setLoading(true);
    if (params.total) delete params.total;
    if (type !== "All") params = { ...params, type: type.toLowerCase() };
    setParamObj(params);
    API_CALL.get(BUNDLE_SERVER + "getAll", { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setBundles(res.data.data);
          setParamObj({ ...params, total: res.data.totalCount?.totalRecords });
          setRefetch("");
        } else setBundles([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <TableView
      type={type + " Bundle"}
      loading={loading}
      paramObj={paramObj}
      list={bundles}
      getList={getBundles}
      setSelected={setSelected}
      columns={[
        ...columns,
        // {
        //   title: "Featured",
        //   dataIndex: "featureInMarketPlace",
        //   key: "featureInMarketPlace",
        //   render: (featured, bundle) => (
        //     <Select
        //       placeholder="Featured"
        //       value={featured}
        //       onChange={(featureInMarketPlace) =>
        //         setSelected({ ...bundle, featureInMarketPlace })
        //       }
        //       style={{ width: 120, textAlign: "left" }}
        //     >
        //       <Select.Option value={true}>Yes</Select.Option>
        //       <Select.Option value={false}>No</Select.Option>
        //     </Select>
        //   ),
        // },
      ]}
    />
  );
};
